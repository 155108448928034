'use client';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { useModalQueue } from '$util/hooks/useModalQueue';
import type { ButtonBaseProps, HTMLButtonProps } from '@/components/atoms/Button';
import { Button } from '@/components/atoms/Button';
import type { QueuedModalDesign } from '@/types/components';

export interface ModalQueueProps {
  /**
   * The modal content.
   */
  readonly children: ReactNode;
  readonly onClose?: () => void;
  /**
   * Track analytics event like `modal:{event-name}`.
   */
  readonly trackShowModal?: string | readonly [name: string, triggeredFrom: string];
  readonly modalDesign?: QueuedModalDesign;
}

type ToggleButtonProps = HTMLButtonProps &
  ButtonBaseProps & {
    readonly toggleButtonText: ReactNode;
  };

interface ControlModalProps {
  readonly showModal: boolean;
}

export type QueueModalProps = ModalQueueProps & (ToggleButtonProps | ControlModalProps);

/**
 * @warn requires `<QueuedModals />` on the page to render the modal.
 */
export function ModalTrigger({
  children,
  onClose,
  trackShowModal,
  modalDesign = 'modal',
  ...props
}: QueueModalProps): ReactNode {
  const [, queueModal, unqueueModal] = useModalQueue();

  const isToggleButtonHidden = 'showModal' in props;
  const showModal = isToggleButtonHidden && props.showModal;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    const modal = queueModal([<Fragment key="modal-content">{children}</Fragment>, modalDesign]);
    if (trackShowModal) {
      if (typeof trackShowModal === 'string') {
        modal.trackModalOpen(trackShowModal);
      } else {
        modal.trackModalOpen(...trackShowModal);
      }
    }
  }, [children, modalDesign, queueModal, trackShowModal]);

  useEffect(() => {
    if (!isModalOpen && showModal) {
      openModal();
      setIsModalOpen(true);
    }
  }, [isModalOpen, openModal, showModal]);

  useEffect(() => {
    const handleCloseModal = (): void => {
      setIsModalOpen(false);
      unqueueModal();
      onClose?.();
    };
    document.addEventListener('closeModal', handleCloseModal);
    return () => {
      document.removeEventListener('closeModal', handleCloseModal);
    };
  }, [onClose, unqueueModal]);

  if (isToggleButtonHidden) return null;
  const { toggleButtonText, className, ...buttonProps } = props;

  return (
    !isToggleButtonHidden && (
      <Button className={classNames(ModalTrigger.name, className)} onClick={openModal} {...buttonProps}>
        {toggleButtonText}
      </Button>
    )
  );
}
